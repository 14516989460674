import React, { Component } from 'react';
import Iframe from 'react-iframe';
import NavMenu from 'App/shared/components/NavMenu';
import { Box } from 'rebass';
import styled from 'styled-components';
import {
  Container,
  Grid,
  Header,
  Modal,
  Responsive,
  Button,
  Icon
} from 'semantic-ui-react';

const FullGrid = styled.div`
  && {
    position: absolute;
    top: 55px;
    left: 0;
    height: calc(100vh - 55px) !important;
    width: 100vw !important;

    iframe {
      border: none;
      height: calc(100vh - 55px);
      width: 90vw;
      min-width: ${props => props.minWidth};
      max-width: 1400px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;


function Mobile() {
  return (
    <Responsive {...Responsive.onlyMobile}>
      <Grid>
        <Container>
          <Header as="h1" style={{ marginTop: '30px' }}>
            Please try this page on desktop!
          </Header>
          <p>
            The LDA topics tool uses advanced rendering codes and
            should be viewed on a desktop/laptop.
          </p>
        </Container>
      </Grid>
    </Responsive>
  );
}

function Desktop() {
  return (
    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
      <FullGrid minWidth={Responsive.onlyTablet.minWidth}>
        <Iframe
          url="/_lda/iframe.html"
          width="100%"
          height="100%"
          id="lda-topics"
          display="initial"
          position="relative"
        />
      </FullGrid>
    </Responsive>
  );
}

export default function LDATopics() {
  return (
    <Box width={1}>
      <Box width={1}>
        <NavMenu />
      </Box>
      <>
        <Desktop />
        <Mobile />
      </>
    </Box>
  );
}
